import React, { useEffect, useRef, useState } from "react";
import FixedCTA from "../../components/FixedCTA";
import Selector from "../../components/Selector";
import { sistema } from "../../model/Sistema";
import { getUIData, sendRequest } from '../../lib/serverAPI';
import Membresia from "../../components/Membresia";
import TituloConBack from "../../components/TituloConBack";
import APIManager from '../../managers/APIManager';
import TarjetaDeEmpleado from "./components/TarjetaDeEmpleado";
import TarjetaDeContrato from "./components/TarjetaDeContrato";
import { AppBar, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, Toolbar } from "@mui/material";
import { variablesAplicadas } from "../../lib/templateFunctions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ordenPorNombreYApellido } from "../../lib/sortFunctions";

export default ({usuario, setUsuario, ...props}) => {
  const [nombres, setNombres] = useState(usuario.nombres);
  const [apellidos, setApellidos] = useState(usuario.apellidos);
  const [emailDeContacto, setEmailDeContacto] = useState(usuario.emailDeContacto);
  const [tiposDeDocumento, setTiposDeDocumento] = useState([]);
  const [tipoDeDocumento, setTipoDeDocumento] = useState(usuario.tipoDeDocumento);
  const [numeroDeDocumento, setNumeroDeDocumento] = useState(usuario.numeroDeDocumento);
  const [genero, setGenero] = useState(usuario.genero);
  const [nacionalidad, setNacionalidad] = useState(usuario.nacionalidad);
  const [fechaDeNacimiento, setFechaDeNacimiento] = useState(usuario.fechaDeNacimiento);
  const [residencia, setResidencia] = useState(usuario.residence);
  const [residenciaValida, setResidenciaValida] = useState(true);
  const [rubro, setRubro] = useState(usuario.profesion);
  const [telefono, setTelefono] = useState(usuario.telefono);
  const [espacios, setEspacios] = useState(sistema.espacios);
  const [espacio, setEspacio] = useState(sistema.espacio);
  const [aspectosProfesionales, setAspectosProfesionales] = useState(usuario.aspectosProfesionales);
  const [professionalHighlights, setProfessionalHighlights] = useState(usuario.professionalHighlights);
  const [professionalKeywords, setProfessionalKeywords] = useState(usuario.professionalKeywords);
  const [aptitudes, setAptitudes] = useState(usuario.aptitudes);
  const [aptitud, setAptitud] = useState(null);
  const [aptitudesGenerales, setAptitudesGenerales] = useState([]);

  const [miembroARemover, setMiembroARemover] = useState(null);
  const [showConfirmarRemover, setShowConfirmarRemover] = useState(false);

  const [avatar, setAvatar] = useState({ url: usuario.avatar });
  const [logoDeEmpresa, setLogoDeEmpresa] = useState({ url: usuario.empresa?.logo ?? null });
  const [imagenCambiada, setImagenCambiada] = useState(false);

  const inputRef = useRef(null);

  const [datosCompletos, setDatosCompletos] = useState(false);

  const [viendoIndividuo, setViendoIndividuo] = useState(true);

  const [empresa, setEmpresa] = useState(null);

  const [nombreDeEmpresa, setNombreDeEmpresa] = useState('');
  const [descripcionDeEmpresa, setDescripcionDeEmpresa] = useState('');
  const [rubroDeEmpresa, setRubroDeEmpresa] = useState(null);
  const [webDeEmpresa, setWEBDeEmpresa] = useState('');
  const [empleados, setEmpleados] = useState([]);
  const [empleadosPendientes, setEmpleadosPendientes] = useState([]);
  const [staff, setStaff] = useState([]);
  const [rubrosDeEmpresa, setRubrosDeEmpresa] = useState([]);
  const [rubrosGenerales, setRubrosGenerales] = useState([]);

  const [nacionalidades, setNacionalidades] = useState([]);
  const [generos, setGeneros] = useState([]);

  const [nuevoAdministrador, setNuevoAdministrador] = useState(null);
  const [agregandoAdministrador, setAgregandoAdministrador] = useState(false);

  const [agregandoEmpleado, setAgregandoEmpleado] = useState(false);
  const [nombresNuevo, setNombresNuevo] = useState('');
  const [apellidosNuevo, setApellidosNuevo] = useState('');
  const [emailNuevo, setEmailNuevo] = useState('');
  const [cargoNuevo, setCargoNuevo] = useState('');
  const [conAccesoACreditosNuevo, setConAccesoACreditosNuevo] = useState(false);

  const [adminAccess, setAdminAccess] = useState(false);

  const [documentoContent, setDocumentoContent] = useState('');
  const [viendoContrato, setViendoContrato] = useState(false);

  const history = useHistory();

  const usuarioModificado = () => {
    return (nombres !== usuario.nombres)
      || (apellidos !== usuario.apellidos)
      || (emailDeContacto !== usuario.emailDeContacto)
      || (espacio !== sistema.espacio)
      || (aspectosProfesionales !== usuario.aspectosProfesionales)
      || (professionalKeywords !== usuario.professionalKeywords)
      || (professionalHighlights !== usuario.professionalHighlights)
      || (aptitudes !== usuario.aptitudes)
      || (telefono !== usuario.telefono)
      || (tipoDeDocumento?.id !== usuario.tipoDeDocumento?.id)
      || (numeroDeDocumento !== usuario.numeroDeDocumento)
      || (genero?.id !== usuario.genero?.id )
      || (nacionalidad?.id !== usuario.nacionalidad?.id)
      || ((residencia !== usuario.residence) && residenciaValida)
      || (rubro?.id !== usuario.profesion?.id)
      || (fechaDeNacimiento !== usuario.fechaDeNacimiento)
      || imagenCambiada
  }

  const empresaModificada = () => {
    if (!!empresa) {
      return (nombreDeEmpresa !== empresa.nombre)
        || (descripcionDeEmpresa !== empresa.descripcion)
        || (webDeEmpresa !== empresa.web)
        || imagenCambiada
    } else {
      return false;
    }

  }

  const quitarAptitud = (item) => {
    setAptitudes(aptitudes.filter(aptitud => aptitud.id !== item.id));
  }

  const agregarAptitud = (item) => {
    setAptitudes([...aptitudes, item]);
    setAptitud(null);
  }

  const quitarRubroDeEmpresa = (item) => {
    setRubrosDeEmpresa(rubrosDeEmpresa.filter(rubro => rubro.id !== item.id));
  }

  const agregarRubroDeEmpresa = (item) => {
    setRubrosDeEmpresa([...rubrosDeEmpresa, item]);
    setRubroDeEmpresa(null);
  }

  const guardarCambios = () => {

    if (!!viendoIndividuo) {
      // Guardamos cambios del usuario
      sistema.actual.usuario = usuario;
      sistema.actual.usuario.nombres = nombres;
      sistema.actual.usuario.apellidos = apellidos;
      sistema.actual.usuario.emailDeContacto = emailDeContacto;
      sistema.actual.usuario.tipoDeDocumento = tipoDeDocumento;
      sistema.actual.usuario.numeroDeDocumento = numeroDeDocumento;
      sistema.actual.usuario.genero = genero;
      sistema.actual.usuario.fechaDeNacimiento = fechaDeNacimiento;
      sistema.actual.usuario.nacionalidad = nacionalidad;
      sistema.actual.usuario.residence = residencia;
      sistema.actual.usuario.espacio = espacio;
      sistema.actual.usuario.aspectosProfesionales = aspectosProfesionales;
      sistema.actual.usuario.professionalHighlights = professionalHighlights;
      sistema.actual.usuario.professionalKeywords = professionalKeywords;
      sistema.actual.usuario.aptitudes = aptitudes;
      sistema.actual.usuario.telefono = telefono;
      sistema.actual.usuario.profesion = rubro;
      sistema.actual.usuario.avatar = avatar.url;

      sistema.saveActual();

      sistema.actual.usuario.avatar = avatar;
      sendRequest('post', 'usuario','modificarUsuario',sistema.actual,(response) => {
        if (response.success) {
          sistema.espacio = espacio;
          sistema.actual.usuario.avatar = avatar.url;
          sistema.saveActual();
          sistema.refreshUser(setUsuario);
        }
      });
    }
    else {
      // Guardamos cambios de la empresa
      sistema.actual.empresa = empresa;
      sistema.actual.empresa.nombre = nombreDeEmpresa;
      sistema.actual.empresa.web = webDeEmpresa;
      sistema.actual.empresa.descripcion = descripcionDeEmpresa;
      sistema.saveActual();

      sistema.actual.empresa.logo = logoDeEmpresa;
      sendRequest('post', 'empresa', 'modificarEmpresa', sistema.actual ,(response) => {
        if (response.success) {
          sistema.actual.empresa.logo = logoDeEmpresa.url;
          sistema.saveActual();
          cargarEmpresa();
          sistema.refreshUser(setUsuario);
        }
      });
    }

    setImagenCambiada(false);
    setDatosCompletos(false);

  }

  const agregarEmpleado = () => {
    if (!!emailNuevo && !!nombresNuevo && !!apellidosNuevo) {
      sendRequest('post', 'empresa', 'nuevoEmpleado', { email: emailNuevo, nombres: nombresNuevo, apellidos: apellidosNuevo, conAccesoACreditos: conAccesoACreditosNuevo, cargo: cargoNuevo}, response => {
        if (response.success) {
          setAgregandoEmpleado(false);
          cargarEmpresa();
        }
      });
    }
  }

  const agregarAdministrador = () => {
    if (!!nuevoAdministrador) {
      sendRequest('post', 'empresa', 'agregarStaff', { empresaID: usuario.empresa.id, staffID: nuevoAdministrador.id }, response => {
        if (response.success) {
          setNuevoAdministrador(null);
          setAgregandoAdministrador(false);
          cargarEmpresa();
        }
      });
    }
  }

  const removerAdministrador = (staffID) => {
    sendRequest('post', 'empresa', 'removerStaff', { empresaID: usuario.empresa.id, staffID }, response => {
      if (response.success) {
        cargarEmpresa();
      }
    });
  }

  const aceptarMiembro = (miembro) => {
    sendRequest('post', 'empresa', 'aceptarEmpleado', { empresaID: usuario.empresa.id, empleado: miembro }, response => {
      if (response.success) {
        cargarEmpresa();
      }
    });
  }

  const confirmarRemover = (miembro) => {
    setMiembroARemover(miembro);
    setShowConfirmarRemover(true);
  }

  const rechazarMiembro = (miembro, callback = null) => {
    sendRequest('post', 'empresa', 'rechazarEmpleado', { empresaID: usuario.empresa.id, empleado: miembro }, response => {
      if (response.success) {
        if (!!callback) {
          callback();
        }
        cargarEmpresa();
      }
    });
  }

  const toggleAccesoACuenta = (miembro) => {
    sendRequest('post', 'empresa', 'accesoACuenta', { empresaID: usuario.empresa.id, empleado: miembro, permitido: !miembro.accesoACuenta }, response => {
      if (response.success) {
        cargarEmpresa();
      }
    });
  }

  const cargarAptitudes = () => {
    APIManager.getList('skills', {}, (response) => {
      setAptitudesGenerales(response.data.listado.map(item => {
        item.nombre = item.name;
        return item;
      }));
    })
  }

  const cargarRubros = () => {
    APIManager.getList('profesiones', {}, (response) => {
      setRubrosGenerales(response.data.listado.map(item => {
        // item.nombre = item.name;
        return item;
      }));
    })
  }

  const cargarGeneros = () => {
    APIManager.getList('generos', {}, (response) => {
      setGeneros(response.data.listado.map(item => {
        // item.nombre = item.name;
        return item;
      }));
    })
  }

  const cargarNacionalidades = () => {
    APIManager.getList('nacionalidades', {}, (response) => {
      setNacionalidades(response.data.listado.map(item => {
        // item.nombre = item.name;
        return item;
      }));
    })
  }

  const cargarTiposDeDocumento = () => {
    APIManager.getList('tiposDeDocumento', {orderBy: 'nombre'}, (response) => {
      setTiposDeDocumento(response.data.listado.map(item => {
        return item;
      }));
    })
  }

  const cargarEmpresa = () => {
    sendRequest('post', 'empresa', 'getEmpresa', { empresaID: usuario.empresa.id }, response => {
      if (response.success && response.data.empresa) {
        response.data.empresa.empleados = Object.values(response.data.empresa.empleados);
        response.data.empresa.empleados = response.data.empresa.empleados.filter(item => item.activo);
        response.data.empresa.staff = Object.values(response.data.empresa.staff);
        setEmpresa(response.data.empresa);
      }
    });
  }

  const handleCloseConfirmarRemover = () => {
    setMiembroARemover(null);
    setShowConfirmarRemover(false);
  }

  const handleCloseContrato = () => {
    setViendoContrato(false);
  }

  const handleConfirmarRemover = () => {
    rechazarMiembro(miembroARemover, handleCloseConfirmarRemover);
  }

  const handleVerContrato = () => {
    if (!!usuario.contrato.documentoURLFull) {
      window.open(usuario.contrato.documentoURLFull);
    } else if (!!usuario.contrato.template_id) {
      sendRequest('post', 'contratos', 'getTemplate', { id: usuario.contrato.template_id }, response => {
        if (response.success) {
          const template = response.data.template;
          variablesAplicadas(template, [], usuario.contrato, htmlBody => {
            setDocumentoContent(htmlBody);
            setViendoContrato(true);
          });
        }
      });
    }
  }

  const handleFirmarContrato = () => {
    history.push('/firmar');
  }

  const handleNuevoIntegrante = () => {
    setConAccesoACreditosNuevo(false);
    setNombresNuevo('');
    setApellidosNuevo('');
    setEmailNuevo('');
    setCargoNuevo('');
    setAgregandoEmpleado(true);
  }

  useEffect(() => {
    if (!viendoIndividuo && !empresa) {
      cargarEmpresa();
    }
  }, [viendoIndividuo]);

  useEffect(() => {
    if (!!empresa) {
      setNombreDeEmpresa(empresa.nombre);
      setWEBDeEmpresa(empresa.web);
      setDescripcionDeEmpresa(empresa.descripcion);
      setRubrosDeEmpresa(Object.values(empresa.categorias));
      setEmpleados(empresa.empleados.filter(item => item.activo && !item.pendiente).map(item => {
        item.nombre = item.nombres + ' ' + item.apellidos;
        return item;
      }));
      setEmpleadosPendientes(empresa.empleados.filter(item => item.activo && item.pendiente).map(item => {
        item.nombre = item.nombres + ' ' + item.apellidos;
        return item;
      }));
      setStaff(empresa.staff);
      setAdminAccess(!!empresa.staff.find(item => item.id === usuario.id));
    }
  }, [empresa]);

  const cambiarImagen = async (event) => {
    // setImagenCambiada(true);
    const imageFiles = event.target.files;
    if (imageFiles.length > 0) {
      const base64file = await toBase64(imageFiles[0]);
      setAvatar({ file: base64file, name: imageFiles[0].name, tipo: 'avatarDeUsuario', id: usuario.id, url: URL.createObjectURL(imageFiles[0]) });
      setImagenCambiada(true);
    }

  }

  const cambiarImagenEmpresa = async (event) => {
    // setImagenCambiada(true);
    const imageFiles = event.target.files;
    if (imageFiles.length > 0) {
      const base64file = await toBase64(imageFiles[0]);
      setLogoDeEmpresa({ file: base64file, name: imageFiles[0].name, tipo: 'logoDeEmpresa', id: empresa.id, url: URL.createObjectURL(imageFiles[0]) });
      setImagenCambiada(true);
    }

  }

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const validarDireccion = () => {
    getUIData('getAddressValidation', { address: residencia }, responseData => {
      if (!!responseData?.result?.verdict && !!responseData?.result?.address?.formattedAddress) {
        setResidenciaValida(true);
        setResidencia(responseData.result.address.formattedAddress);
      }
    })
  }

  useEffect(() => {
    if (!!usuario) {
      if (!!usuario.contrato && (usuario.contrato.cliente.tipo !== 'EMPRESA')) {
        setAdminAccess(true);
      }
    }
  }, [usuario]);

  useEffect(() => {
    if ((tiposDeDocumento.length > 0) && !!tipoDeDocumento && !tipoDeDocumento?.nombre) {
      setTipoDeDocumento(tiposDeDocumento.find(item => item.id === tipoDeDocumento.id));
    }
  }, [tiposDeDocumento]);

  useEffect(() => {
    if ((generos.length > 0) && !!genero && !genero?.nombre) {
      setGenero(generos.find(item => item.id === genero.id));
    }
  }, [generos]);

  useEffect(() => {
    if ((nacionalidades.length > 0) && !!nacionalidad && !nacionalidad?.nombre) {
      setNacionalidad(nacionalidades.find(item => item.id === nacionalidad.id));
    }
  }, [nacionalidades]);

  useEffect(() => {
    if ((rubrosGenerales.length > 0) && !!rubro && !rubro?.nombre) {
      setNacionalidad(rubrosGenerales.find(item => item.id === rubro.id));
    }
  }, [rubrosGenerales]);

  useEffect(() => {
    setDatosCompletos(viendoIndividuo ? (usuarioModificado() && nombres && apellidos && espacio && emailDeContacto) : empresaModificada());
  }, [viendoIndividuo, nombres, apellidos, espacio, emailDeContacto, aspectosProfesionales, professionalHighlights, professionalKeywords, aptitudes, telefono, nombreDeEmpresa, imagenCambiada, descripcionDeEmpresa, webDeEmpresa, tipoDeDocumento, numeroDeDocumento, genero, nacionalidad, fechaDeNacimiento, residencia, residenciaValida]);

  useEffect(() => {
    cargarAptitudes();
    cargarRubros();
    cargarTiposDeDocumento();
    cargarNacionalidades();
    cargarGeneros();
  }, []);

  return (
    <div className="perfil-page">
      <TituloConBack titulo="Perfil" />
      {!!viendoContrato && !!documentoContent &&
        <Dialog
          fullScreen
          open={viendoContrato} onClose={handleCloseContrato}>
          <AppBar sx={{ position: 'relative' }} >
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseContrato}
                aria-label="close"
              >
                <i className="icon--times"></i>
                {/* <CloseIcon /> */}
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <Paper elevation={3} sx={{ padding: '24px' }} dangerouslySetInnerHTML={{ __html: documentoContent }} />
          </DialogContent>
          <DialogActions><Button onClick={handleCloseContrato}>Cerrar</Button></DialogActions>
        </Dialog>
      }
      {!!showConfirmarRemover && !!miembroARemover &&
        <Dialog
          fullScreen
          open={showConfirmarRemover} onClose={handleCloseConfirmarRemover}>
          <AppBar sx={{ position: 'relative' }} >
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseConfirmarRemover}
                aria-label="close"
              >
                <i className="icon--times"></i>
                {/* <CloseIcon /> */}
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContentText>
            <p>Confirma quitar a </p>
          </DialogContentText>
          <DialogActions><Button onClick={handleConfirmarRemover}>Confirmar</Button></DialogActions>
        </Dialog>
      }
      {!!usuario.empresa && <div className="tipo-de-perfil--selector">
        <div className={"selector-link" + (viendoIndividuo ? ' is--selected' : '')} onClick={() => {setViendoIndividuo(true)}}>Individuo</div>
        <div className={"selector-link" + (!viendoIndividuo ? ' is--selected' : '')} onClick={() => {setViendoIndividuo(false)}}>Empresa</div>
      </div>}
      {!!viendoIndividuo
        ? <div className="wrapper--scrollable-content__layout" >
          <div className="wrapper--scrollable-content__column" >
            <section className="perfil-section">
              <button className="button--user-avatar" title="Avatar" onClick={() => {inputRef.current.click()}}>
                <div className="button--user-avatar__content-wrapper">
                  <span className="button--user-avatar__notification-icon">+</span>
                  {avatar.url
                    ? <img className="button--user-avatar__image no-bgcolor" src={avatar.url} alt="" />
                    : <div className="button--user-avatar__image" ><span className="button--user-avatar__text" >{(usuario.nombres ? usuario.nombres[0] : '') + (usuario.apellidos ? usuario.apellidos[0] : '')}</span></div>
                  }
                </div>
              </button>
              <input ref={inputRef} accept="image/*" style={{display: 'none'}} type="file" onChange={cambiarImagen} />
              <div className="input-label-combo">
                <input className={"inputNombres" + (nombres ? ' tiene-valor' : '')} id="inputNombres" type="text" onInput={(event) => {setNombres(event.target.value)}} value={nombres} required />
                <label className="is--placeholder" htmlFor="inputNombres">Nombres</label>
              </div>
              <div className="input-label-combo">
                <input className={"inputApellidos" + (apellidos ? ' tiene-valor' : '')} id="inputApellidos" type="text" onInput={(event) => {setApellidos(event.target.value)}} value={apellidos} required />
                <label className="is--placeholder" htmlFor="inputApellidos">Apellidos</label>
              </div>
              <div className="input-label-combo">
                <input className={"inputEmailDeContacto" + (emailDeContacto ? ' tiene-valor' : '')} id="inputEmailDeContacto" type="text" onInput={(event) => {setEmailDeContacto(event.target.value)}} value={emailDeContacto} required />
                <label className="is--placeholder" htmlFor="inputEmailDeContacto">Email de contacto</label>
              </div>
              <div className="perfil--row">
                <div className="input-label-combo">
                  <input className="tiene-valor input-invisible" style={{position: 'absolute', zIndex: -1}} disabled id="inputGenero" value={!!genero ? genero.nombre : ''} ></input>
                  <label className="is--placeholder" htmlFor="inputGenero">Género</label>
                  <Selector titulo="Género" className="genero--selector" selectedItem={genero} items={generos} icon="" onChange={setGenero} folded={true} required />
                </div>
                <div className="input-label-combo fecha-nacimiento" style={{margin: 0}}>
                  <input className={"inputFechaDeNacimiento tiene-valor"} id="inputFechaDeNacimiento" type="date" onInput={(event) => {setFechaDeNacimiento(event.target.value)}} value={fechaDeNacimiento} required />
                  <label className="is--placeholder" htmlFor="inputFechaDeNacimiento">Fecha de Nacimiento</label>
                </div>
              </div>
              <div className="perfil--row">
                <div className="input-label-combo" style={{margin: 0}}>
                  <input className="tiene-valor input-invisible" style={{position: 'absolute', zIndex: -1}} disabled ></input>
                  <label className="is--placeholder" >Nacionalidad</label>
                  <Selector titulo="Nacionalidad" className="nacionalidad--selector" selectedItem={nacionalidad} items={nacionalidades} icon="" onChange={setNacionalidad} folded={true} required />
                </div>
              </div>
              <div className="perfil--row">
                <div className="residencia">
                  <div className="input-label-combo">
                    <input className={"inputResidencia" + (!residenciaValida ? ' invalida' : '') + (residencia ? ' tiene-valor' : '')} id="inputResidencia" type="text" onInput={(event) => { setResidencia(event.target.value); setResidenciaValida(false)}} value={residencia} />
                    <label className="is--placeholder" htmlFor="inputResidencia">Lugar de residencia</label>
                  </div>
                  {!residenciaValida && <button className="button--icon-only button--no-background validar-residencia" onClick={validarDireccion}><i className="icon--check"></i></button>}
                </div>
              </div>
              <div className="documento">
                <div className="input-label-combo tipo-documento">
                  <input className="tiene-valor input-invisible" disabled ></input>
                  <label className="is--placeholder" >Tipo de documento</label>
                  <Selector titulo="Tipo de documento" className="tipo-documento--selector" selectedItem={tipoDeDocumento} items={tiposDeDocumento} icon="" onChange={setTipoDeDocumento} folded={true} required />
                </div>
                <div className="input-label-combo numero-documento">
                  <input className={"inputNumeroDeDocumento" + (numeroDeDocumento ? ' tiene-valor' : '')} id="inputNumeroDeDocumento" type="text" onInput={(event) => {setNumeroDeDocumento(event.target.value)}} value={numeroDeDocumento} required />
                  <label className="is--placeholder" htmlFor="inputNumeroDeDocumento">Numero de Documento</label>
                </div>
              </div>
              {!usuario.empresa &&
              <div className="input-label-combo">
                  <input className="tiene-valor input-invisible" style={{position: 'absolute', zIndex: -1}} disabled ></input>
                  <label className="is--placeholder" >Profesión</label>
                  <Selector titulo="Profesión" className="profesion--selector" selectedItem={rubro} items={rubrosGenerales} icon="" onChange={setRubro} folded={true} required />
                </div>}
              <div className="input-label-combo telefono">
                <input className={"inputTelefono" + (telefono ? ' tiene-valor' : '')} id="inputTelefono" type="text" onInput={(event) => {setTelefono(event.target.value)}} value={telefono} />
                <label className="is--placeholder" htmlFor="inputTelefono">Telefono de contacto</label>
              </div>
              {/* <h1 className="titulo sedes__title">Sede de preferencia</h1>
              <Selector titulo="Sede" selectedItem={espacio} items={espacios} icon="" onChange={setEspacio} folded={true} /> */}
              <div className="perfil--row">
                <div className="input-label-combo">
                  <input className="tiene-valor input-invisible" style={{position: 'absolute', zIndex: -1}} disabled ></input>
                  <label className="is--placeholder" >Sede de preferencia</label>
                  <Selector titulo="Sede de preferencia" className="sede--selector" selectedItem={espacio} items={espacios} icon="" onChange={setEspacio} folded={true} />
                </div>
              </div>
            </section>
            <section className="perfil-section">
              <h1 className="titulo">Información para comunidad</h1>
              <div className="aptitudes">
              <h1 className="subtitulo">Aptitudes</h1>
                {aptitudes && aptitudes.map((item, index) =>
                  <div key={item.id} className="aptitud">
                    <span>{item.name}</span>
                    <button className="aptitud-button" onClick={() => quitarAptitud(item)}><i className="icon--times"></i></button>
                  </div>)}
              </div>
              <div className="aptitudes-combo">
                <Selector titulo="Agregar aptitud" className="aptitudes--selector" selectedItem={aptitud} items={aptitudesGenerales.filter(item => !aptitudes.find(aptitud => aptitud.id === item.id))} icon="" onChange={setAptitud} folded={true} />
                <button className="aptitud-button--nuevo" disabled={!aptitud} onClick={() => agregarAptitud(aptitud)}><i className="icon--plus"></i></button>
              </div>
              <h1 className="titulo aspectosProfesionales__title">Información profesional</h1>
              <div className="input-label-combo">
                <textarea className={"text-area aspectosProfesionales" + (aspectosProfesionales ? ' tiene-valor' : '')} value={aspectosProfesionales} onInput={(event) => {setAspectosProfesionales(event.target.value)}} />
                <label className="is--placeholder">Aspectos profesionales</label>
              </div>
              <div className="input-label-combo">
                <textarea className={"text-area aspectosProfesionales" + (professionalHighlights ? ' tiene-valor' : '')} value={professionalHighlights} onInput={(event) => {setProfessionalHighlights(event.target.value)}} />
                <label className="is--placeholder">Me destaco en</label>
              </div>
              <div className="input-label-combo">
                <textarea className={"text-area aspectosProfesionales" + (professionalKeywords ? ' tiene-valor' : '')} value={professionalKeywords} onInput={(event) => { setProfessionalKeywords(event.target.value) }} />
                <label className="is--placeholder">Palabras clave</label>
              </div>
            </section>
          </div>
          {/* {!props.isPortrait && */}
            <div className="wrapper--scrollable-content__column" >
              <div className="membresia-wrapper">
                <h1 className="membresia__titulo">{usuario.contrato ? 'Tu membresía' : 'Sin Membresía'}</h1>
                  {usuario.contrato && <Membresia {...usuario.membresia} />}
              </div>
              <div>
                {(!!usuario.contrato && (usuario.contrato.cliente.tipo !== 'EMPRESA')) && adminAccess &&
                  <TarjetaDeContrato usuario={usuario} contrato={usuario ? usuario.contrato : null} adminAccess={adminAccess} onVerContrato={handleVerContrato} onFirmarContrato={handleFirmarContrato} />
                }
              </div>
            </div>
          {/* } */}
        </div>
        : // Viendo perfil de empresa
        <div className="wrapper--scrollable-content__layout" >
          <div className="wrapper--scrollable-content__column" >
            <section className="perfil-section">
              <button className="button--user-avatar" title="Avatar" onClick={() => { inputRef.current.click() }}>
                <div className="button--user-avatar__content-wrapper">
                  <span className="button--user-avatar__notification-icon">+</span>
                  {logoDeEmpresa
                    ? <img className="button--user-avatar__image no-bgcolor" src={logoDeEmpresa.url} alt="" />
                    : <div className="button--user-avatar__image"><span className="button--user-avatar__text">{(usuario.empresa.nombre ? usuario.empresa.nombre.substr(0,2) : '')}</span></div>
                  }
                </div>
              </button>
              <input ref={inputRef} accept="image/*" style={{ display: 'none' }} type="file" onChange={cambiarImagenEmpresa} />
              <div className="input-label-combo">
                <input className={"inputNombreDeEmpresa" + (nombreDeEmpresa ? ' tiene-valor' : '')} type="text" onInput={(event) => {setNombreDeEmpresa(event.target.value)}} value={nombreDeEmpresa} />
                <label className="is--placeholder" >Nombre</label>
              </div>
              <div className="input-label-combo">
                <textarea className={"text-area inputDescripcionDeEmpresa" + (descripcionDeEmpresa ? ' tiene-valor' : '')} type="text" onInput={(event) => {setDescripcionDeEmpresa(event.target.value)}} value={descripcionDeEmpresa} />
                <label className="is--placeholder" >Descripcion</label>
              </div>
              <h1 className="titulo aspectosProfesionales__title">Rubros</h1>
              <div className="aptitudes-combo">
                <Selector titulo="Rubro" className="aptitudes--selector" selectedItem={rubroDeEmpresa} items={rubrosGenerales.filter(item => !rubrosDeEmpresa.find(rubro => rubro.id === item.id))} icon="" onChange={setRubroDeEmpresa} folded={true} />
                <button className="aptitud-button--nuevo" disabled={!rubroDeEmpresa} onClick={() => agregarRubroDeEmpresa(rubroDeEmpresa)}><i className="icon--plus"></i></button>
              </div>
              <div className="aptitudes">
                {rubrosDeEmpresa && rubrosDeEmpresa.map((item, index) =>
                  <div key={item.id} className="aptitud">
                    <span>{item.name}</span>
                    <button className="aptitud-button" onClick={() => quitarRubroDeEmpresa(item)}><i className="icon--times"></i></button>
                  </div>)}
              </div>
              <div className="input-label-combo">
                <input className={"inputWEBEmpresa" + (webDeEmpresa ? ' tiene-valor' : '')} type="text" onInput={(event) => {setWEBDeEmpresa(event.target.value)}} value={webDeEmpresa} />
                <label className="is--placeholder">Pagina WEB</label>
              </div>

              {(empleados.length > 0) && <>
                <h1 className="titulo listaDeEmpleados__title">Integrantes</h1>
                <ol className="lista-de-empleados">
                  {empleados.sort(ordenPorNombreYApellido).map(item =>
                    <li key={item.id} className="tarjeta-de-empleado">
                      <TarjetaDeEmpleado item={item} conPuesto={true} >
                        {adminAccess &&
                          // <button className={"control--acceso-cuenta" + (item.accesoACuenta ? ' permitido' : ' no-permitido')} title="Acceso a créditos de la empresa" onClick={() => toggleAccesoACuenta(item)}>
                          <button className={"control--acceso-cuenta"} title={(item.accesoACuenta ? 'Con ' : 'Sin ') + "acceso a créditos de la empresa"} onClick={() => toggleAccesoACuenta(item)}>
                          {/* {item.accesoACuenta
                            ? <i className="icon--dollar"></i>
                            : <i className="icon--dollar"></i>
                          } */}
                          {item.accesoACuenta
                            ? <img className="control--icon" src="img/coin-green.png" alt="permitido" />
                            : <img className="control--icon" src="img/coin-red.png" alt="no permitido" />
                          }
                        </button>}
                        {adminAccess && <button className="control--remover-miembro" title="Quitar empleado"  onClick={() => confirmarRemover(item)}>Remover</button>}
                      </TarjetaDeEmpleado>
                    </li>
                  )}
                  {adminAccess && (!agregandoEmpleado
                  ? <li className="tarjeta-agregar-staff">
                    <button className="control--agregar-staff" onClick={handleNuevoIntegrante}>Nuevo Integrante</button>
                  </li>
                  : <li className="tarjeta-agregar-staff">
                      <div className="layout--row">
                        <div className="layout--column">
                          <div className="input-label-combo">
                            <input className={"inputNombres" + (nombresNuevo ? ' tiene-valor' : '')} id="inputNombresNuevo" type="text" onInput={(event) => { setNombresNuevo(event.target.value) }} value={nombresNuevo} />
                            <label className="is--placeholder" htmlFor="inputNombresNuevo">Nombres</label>
                          </div>
                          <div className="input-label-combo">
                            <input className={"inputNombres" + (apellidosNuevo ? ' tiene-valor' : '')} id="inputApellidosNuevo" type="text" onInput={(event) => { setApellidosNuevo(event.target.value) }} value={apellidosNuevo} />
                            <label className="is--placeholder" htmlFor="inputApellidosNuevo">Apellidos</label>
                          </div>
                          <div className="input-label-combo">
                            <input className={"inputNombres" + (emailNuevo ? ' tiene-valor' : '')} id="inputEmailNuevo" type="text" onInput={(event) => { setEmailNuevo(event.target.value) }} value={emailNuevo} />
                            <label className="is--placeholder" htmlFor="inputEmailNuevo">Email</label>
                          </div>
                          <div className="input-label-combo">
                            <input className={"inputNombres" + (cargoNuevo ? ' tiene-valor' : '')} id="inputCargoNuevo" type="text" onInput={(event) => { setCargoNuevo(event.target.value) }} value={cargoNuevo} />
                            <label className="is--placeholder" htmlFor="inputCargoNuevo">Cargo</label>
                          </div>
                          <div className="nuevo-integrante--acceso">
                            <span>Acceso al pool de créditos</span>
                            <button className={"control--acceso-cuenta"} title={(conAccesoACreditosNuevo ? 'Con ' : 'Sin ') + "acceso a créditos de la empresa"} onClick={() => setConAccesoACreditosNuevo(!conAccesoACreditosNuevo)}>
                              {conAccesoACreditosNuevo
                                ? <img className="control--icon" src="img/coin-green.png" alt="permitido" />
                                : <img className="control--icon" src="img/coin-red.png" alt="no permitido" />
                              }
                            </button>
                          </div>
                        </div>
                        <div className="layout--column layout--controles">
                        <button className="control--aceptar-miembro" disabled={!nombresNuevo || !apellidosNuevo || !emailNuevo} onClick={agregarEmpleado}>
                          <i className="icon--check"></i>
                        </button>
                        <button className="control--rechazar-miembro" onClick={() => { setAgregandoEmpleado(false) }}>
                          <i className="icon--times"></i>
                        </button>
                      </div>
                    </div>
                  </li>)}
                </ol>
              </>}

            {(staff.length > 0) && <>
              <h1 className="titulo listaDeEmpleados__title">Equipo Administrador</h1>
              <ol className="lista-de-empleados">
                {staff.sort(ordenPorNombreYApellido).map(item =>
                  <li key={item.id} className="tarjeta-de-empleado">
                    <TarjetaDeEmpleado item={item} >
                      {adminAccess && <button className="control--remover-miembro" onClick={() => removerAdministrador(item.id)}>Remover</button>}
                    </TarjetaDeEmpleado>
                  </li>
                )}
                {adminAccess && (!agregandoAdministrador
                  ? <li className="tarjeta-agregar-staff">
                    <button className="control--agregar-staff" onClick={() => { setAgregandoAdministrador(true) }}>Agregar al Equipo</button>
                  </li>
                  : <li className="tarjeta-agregar-staff">
                    <h2 className="titulo">Agregar al Equipo Administrador</h2>
                    <div className="layout--row">
                      <div className="layout--column">
                        <Selector titulo="Integrante..." selectedItem={nuevoAdministrador} items={empleados} icon="" onChange={setNuevoAdministrador} folded={true} />
                      </div>
                      <div className="layout--column layout--controles">
                        <button className="control--aceptar-miembro" onClick={agregarAdministrador}>
                          <i className="icon--check"></i>
                        </button>
                        <button className="control--rechazar-miembro" onClick={() => { setAgregandoAdministrador(false) }}>
                          <i className="icon--times"></i>
                        </button>
                      </div>
                    </div>
                  </li>)}
              </ol>
            </>}
            </section>
          </div>
          <div className="wrapper--scrollable-content__column" >
            {adminAccess && (empleadosPendientes.length > 0) && <section className="perfil-section">
              <h1 className="titulo listaDeEmpleados__title">Aceptar Integrantes</h1>
              <ol className="lista-de-empleados">
                {empleadosPendientes.sort(ordenPorNombreYApellido).map(item =>
                  <li className="tarjeta-de-empleado">
                    <TarjetaDeEmpleado item={item} conPuesto={true} >
                      <button className="control--aceptar-miembro" onClick={() => aceptarMiembro(item)}><i className="icon--check"></i></button>
                      <button className="control--rechazar-miembro" onClick={() => rechazarMiembro(item)}><i className="icon--times"></i></button>
                    </TarjetaDeEmpleado>
                  </li>
                )}
              </ol>
            </section>}
            <TarjetaDeContrato usuario={usuario} contrato={usuario ? usuario.contrato : null} adminAccess={adminAccess} onVerContrato={handleVerContrato} onFirmarContrato={handleFirmarContrato} />
          </div>
        </div>
      }

      <FixedCTA label="Guardar cambios" disabled={!datosCompletos} onClick={guardarCambios} />
    </div>
  );
}